[
  {
    "name": "osmosis",
    "maxPerDay": 1,
    "apiVersions": {
      "gov": "v1beta1"
    }
  },
  {
    "name": "cosmoshub",
    "ownerAddress": "cosmosvaloper19f0w9svr905fhefusyx4z8sf83j6et0g9l5yhl"
  },
  {
    "name": "evmos",
    "ownerAddress": "evmosvaloper1xaxg47uw046uw8vgpp3da367ctw9aguqjuyyyx",
    "txTimeout": 120000,
    "maxPerDay": 1,
    "authzAminoSupport": false
  },
  {
    "name": "agoric",
    "ownerAddress": "agoricvaloper1nkhsz0uk0d8qd4p7unrwej0j3ne8acpk9dd3sg"
  },
  {
    "name": "decentr",
    "ownerAddress": "decentrvaloper19f0w9svr905fhefusyx4z8sf83j6et0g52df8n"
  },
  {
    "name": "assetmantle",
    "ownerAddress": "mantlevaloper19f0w9svr905fhefusyx4z8sf83j6et0g7slqln"
  },
  {
    "name": "teritori",
    "ownerAddress": "torivaloper19f0w9svr905fhefusyx4z8sf83j6et0g8d58j3"
  },
  {
    "name": "lambda",
    "ownerAddress": "lambvaloper1qt6yf3fvz250uddj04rxglfd40v8nruqplx8us"
  },
  {
    "name": "point",
    "ownerAddress": "pointvaloper1qt6yf3fvz250uddj04rxglfd40v8nruq85akl6"
  },
  {
    "name": "rebus",
    "ownerAddress": "rebusvaloper1qt6yf3fvz250uddj04rxglfd40v8nruq6asnlu"
  },
  {
    "name": "jackal",
    "ownerAddress": "jklvaloper19f0w9svr905fhefusyx4z8sf83j6et0gvxwf62"
  },
  {
    "name": "planq",
    "gasPrice": "30000000000aplanq",
    "ownerAddress": "plqvaloper1qt6yf3fvz250uddj04rxglfd40v8nruqzxjl7t"
  },
  {
    "name": "aura",
    "ownerAddress": "auravaloper19f0w9svr905fhefusyx4z8sf83j6et0gq0xmpt"
  },
  {
    "name": "nois",
    "ownerAddress": "noisvaloper19f0w9svr905fhefusyx4z8sf83j6et0gsr06wq"
  },
  {
    "name": "uptick",
    "ownerAddress": "uptickvaloper1qt6yf3fvz250uddj04rxglfd40v8nruq47xkt5"
  },
  {
    "name": "omniflixhub",
    "ownerAddress": "omniflixvaloper1x70l4dcj30nr4x52ph3jmkpgz79qgktxe0w4tl"
  },
  {
    "name": "migaloo",
    "ownerAddress": "migaloovaloper19f0w9svr905fhefusyx4z8sf83j6et0glwd8vf",
    "gasPriceStep": {
      "low": 0.25,
      "average": 0.5,
      "high": 0.75
    }
  },
  {
    "name": "gitopia",
    "ownerAddress": "gitopiavaloper19f0w9svr905fhefusyx4z8sf83j6et0gkfrk55"
  },
  {
    "name": "sge",
    "ownerAddress": "sgevaloper19f0w9svr905fhefusyx4z8sf83j6et0gy374fm"
  },
  {
    "name": "source",
    "ownerAddress": "sourcevaloper19f0w9svr905fhefusyx4z8sf83j6et0gxxllss"
  },
  {
    "name": "haqq",
    "ownerAddress": "haqqvaloper1qt6yf3fvz250uddj04rxglfd40v8nruqm9kyy7"
  },
  {
    "name": "stratos",
    "ownerAddress": "stvaloper1g8fmnrz00sqh9pz3ghf8ram9q86te804f8pr28"
  },
  {
    "name": "humans",
    "ownerAddress": "humanvaloper1qt6yf3fvz250uddj04rxglfd40v8nruqmumcwn"
  },
  {
    "name": "celestia",
    "ownerAddress": "celestiavaloper19f0w9svr905fhefusyx4z8sf83j6et0g57nch8"
  },
  {
    "name": "nibiru",
    "ownerAddress": "nibivaloper19f0w9svr905fhefusyx4z8sf83j6et0g742nxz"
  },
  {
    "name": "blockx",
    "ownerAddress": "blockxvaloper1yp394rmk45ndk3durlp7ft58pm6rwlnqfa3ucs"
  },
  {
    "name": "zetachain",
    "ownerAddress": "stvaloper1g8fmnrz00sqh9pz3ghf8ram9q86te804f8pr28"
  },
  {
    "name": "dymension",
    "ownerAddress": "dymvaloper1jpvku8u4al5kx4zm3hqm9j9faw3qhx6ss4ueq9"
  },
  {
    "name": "dhealth",
    "ownerAddress": "dhvaloper10n3fs6fkl4fp9dcsdfl2vl3ay7pk7snn33uhmt"
  },
  {
    "name": "saga",
    "ownerAddress": "sagavaloper19f0w9svr905fhefusyx4z8sf83j6et0gsn0qkm"
  },
  {
    "name": "crossfi",
    "ownerAddress": "mxvaloper1x70l4dcj30nr4x52ph3jmkpgz79qgktx7vd2j3"
  }
]
